import { EnvironmentService } from './../services/environment.service';
import { PackagesGuard } from './../packages.guard';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { VehicleConfigLookupService } from '../services/vehicle-config-lookup.service';
import { CloudService } from '../services/cloud.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private router: Router, private guard: PackagesGuard, private cloudService: CloudService,
    private environmentService: EnvironmentService, private vehicleConfigService: VehicleConfigLookupService, private location: Location) { }

  ngOnInit() {
  }

  onClickOfMenuItem(menuItem: string) {
    if (menuItem === 'home') {
      this.cloudService.setIsVehicleInContext(true);
      this.cloudService.setIsMenuClickedInFRM(false);
      this.vehicleConfigService.setVehicleInContext(true);
      this.vehicleConfigService.setManipulatedMaintenanceIntervals([]);
      this.router.navigate(['../' + this.location.path(true)]);
    } else if (menuItem === 'maintenance') {
      this.guard.allow = true;
      this.router.navigateByUrl('packages', { skipLocationChange: true });
    }
  }

  ngAfterViewInit() {
    setTimeout( () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }

  ngOnDestroy() {
    // Garbage collect all subscriptions
  }

}
