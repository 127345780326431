import { VehicleContextForPrint, VehicleContextObj } from './../models/vehicle-context.model';
import { EnvironmentService } from './../services/environment.service';
import { TimeBasedService } from './../models/time-based-service.model';
import { Component, OnInit, HostListener, ViewEncapsulation, Renderer2, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CloudService } from './../services/cloud.service';
import { MaintenancePackage, MaintenanceIntervals } from './../models/maintenance-package.model';
import { MaintenanceLineItem } from './../models/maintenance-line-item.model';
import { CanDeactivateGuard } from '../guards/can-deactivate.guard';
import _ from 'lodash';
import { VehicleConfigLookupService } from './../services/vehicle-config-lookup.service';
import { UpgDialogComponent } from '../upg-dialog/upg-dialog.component';
import { EventReport } from '../models/event-report-model';
import { CONST_EN_US_LANG_CODE, CONST_EN_US_LOCALE, CONST_FR_CA_LANG_CODE, CONST_FR_CA_LOCALE, CONST_FR_LANG, CONST_MAINTENANCE_RESPONSE_TYPE } from '../app.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModelYear } from '../models/model-year.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MaintenanceComponent implements OnInit {

  vehicleContextForPrint: VehicleContextForPrint

  allMaintenancePckgs: MaintenanceIntervals[] = [];
  selectedMaintenancePackage: MaintenanceIntervals;
  maintenanceLineItems: MaintenanceLineItem[] = [];
  timeBasedServices: TimeBasedService[] = [];

  maintenancePageDestroyed$ = new Subject();
  getModelYearErrorOccurred = false;

  currentYear = new Date().getFullYear();

  isMaintenanceRecommendedPackage = false;
  isFixedFooter = true;

  brand = 'vw';
  importerCode = '444';
  modelYears: ModelYear[] = [];

  privacyUrl: string;
  contactUsUrl: string;

  recommendedPackageIndex = 0;
  eventReport: EventReport;
  userSelectedLanguage: string;

  screenHeight;
  isCanadianMarket: boolean = false;
  event: EventReport = {};
  browser: string;
  device: string;
  userAgentString: string;

  constructor(private cloudService: CloudService, private vehicleConfigService: VehicleConfigLookupService,
    private environmentService: EnvironmentService, private router: Router, private location: Location,
    private route: ActivatedRoute, private guard: CanDeactivateGuard, private renderer: Renderer2,
    private dialog: MatDialog, public translate: TranslateService) {
    this.screenHeight = this.getViewportSize().h;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenHeight = this.getViewportSize().h;
    this.calculateScreenHeight();
  }

  ngOnInit() {
    this.userAgentString = navigator.userAgent;
    this.device = this.findDevice();
    this.browser = this.findBrowser(this.userAgentString);
    console.log('=>=>=> VEHICLE CONTEXT NGONINIT MPTS- ', this.vehicleConfigService.getVehicleContext());
    this.initVariables();
    console.log('=>=>=> VEHICLE CONTEXT NGONINIT - ', this.vehicleConfigService.getVehicleContext());
    if (this.cloudService.getIsMenuClickedInFRM()) {
      this.loadMaintenancePackage();
    } else {
      this.timeBasedServices = this.vehicleConfigService.getTimeBasedServices();
      this.buildAllMaintenancePackages(this.vehicleConfigService.getAllMaintenancePackages());
    }
  }

  initVariables() {
    this.userSelectedLanguage = this.environmentService.getLanguage();
    this.vehicleContextForPrint = this.vehicleConfigService.getVehicleContextForPrint();
    this.brand = this.environmentService.getBrand();
    this.importerCode = this.environmentService.getImporterCode();
    this.isCanadianMarket = this.environmentService.isCanadianMarket();
    this.recommendedPackageIndex = 0;
    this.privacyUrl = this.environmentService.getPrivacyUrl();
    this.contactUsUrl = this.environmentService.getContactUsUrl();
    this.allMaintenancePckgs = [];
    this.selectedMaintenancePackage = { 'package_id': '', 'mileage': '', 'recommended': false, 'isSelected': false, maintenance_line_item: [], 'description': '' };
    this.environmentService.removeBackgroundImage();
    this.eventReport = this.vehicleConfigService.getEventReport();
    //document.body.style.backgroundColor = '#f1f5f6';
  }

  findDevice() {
    var ua = navigator.userAgent;
    // Detect Chrome
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mac OS X|Mobile|mobile|CriOS/i.test(ua)) {
      return 'Mobile'
    } else {
      return 'Desktop'
    }
  }

  findBrowser(userAgentString: string) {
    console.log("Browser Type - UserAgent String" + userAgentString)
    if (this.device.indexOf("Mobile") > -1) {
      // Detect Chrome
      if (userAgentString.indexOf("Edg") > -1) { return 'Edge' }

      // Detect Chrome
      if (userAgentString.indexOf("CriOS") > -1) { return 'Chrome' }

      // Detect Firefox
      if (userAgentString.indexOf("Firefox") > -1) { return "Firefox" }

      // Detect Internet Explorer
      if (userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1) { return "IExplorer" }

      // Detect Safari
      if (userAgentString.indexOf("Safari") > -1) { return "Safari" }

      // Detect Opera
      if (userAgentString.indexOf("OP") > -1) { return "Opera" };
    } else {
      // Detect Chrome
      if (userAgentString.indexOf("Edg") > -1) { return 'Edge' }

      // Detect Chrome
      if (userAgentString.indexOf("Chrome") > -1) { return 'Chrome' }

      // Detect Firefox
      if (userAgentString.indexOf("Firefox") > -1) { return "Firefox" }

      // Detect Internet Explorer
      if (userAgentString.indexOf("MSIE") > -1 ||
        userAgentString.indexOf("rv:") > -1) { return "IExplorer" }

      // Detect Safari
      if (userAgentString.indexOf("Safari") > -1) { return "Safari" }

      // Detect Opera
      if (userAgentString.indexOf("OP") > -1) { return "Opera" };
    }

    return 'Other'
  }

  showMessage() {
    if (this.allMaintenancePckgs && parseInt(this.allMaintenancePckgs[this.allMaintenancePckgs.length - 1]['mileage']) < parseInt(this.vehicleContextForPrint?.mileage)) {
      return true;
    }
    return false;
  }

  searchForPackages() {
   const vehicleContext = this.cloudService.getPackageValues();
    this.cloudService.fetchMaintenancePacakges(vehicleContext).pipe(takeUntil(this.maintenancePageDestroyed$)).subscribe((response) => {
     console.log(response);
    //  this.allMaintenancePckgs = response.packages;
     this.timeBasedServices = response.time_based_services;
     this.vehicleConfigService.setTimeBasedServices(this.timeBasedServices);
     this.vehicleConfigService.updateVehicleDetailsResponse(response.packages, CONST_MAINTENANCE_RESPONSE_TYPE, true, false, false);
    //  this.allMaintenancePckgs = this.vehicleConfigService.getAllMaintenancePackages();
    // this.loadMaintenancePackage();
    this.buildAllMaintenancePackages(this.vehicleConfigService.getAllMaintenancePackages());

    })
  }

  loadMaintenancePackage() {
    this.allMaintenancePckgs = this.vehicleConfigService.getManipulatedMaintenanceIntervals();
    this.timeBasedServices = this.vehicleConfigService.getTimeBasedServices();
    let recommendedPackage = {};
    let index = 0;
    for (let interval of this.allMaintenancePckgs) {
      if (interval.isSelected) {
        this.selectedMaintenancePackage = interval;
        this.recommendedPackageIndex = index;
        break;
      } else if (interval.recommended) {
        recommendedPackage = interval;
        this.recommendedPackageIndex = index;
      }
      index = index + 1;
    }
    if (!_.isNil(this.selectedMaintenancePackage.package_id) && this.selectedMaintenancePackage.package_id.length > 0) {
      this.maintenanceLineItems = this.selectedMaintenancePackage['maintenance_line_item'];
    } else {
      this.maintenanceLineItems = recommendedPackage['maintenance_line_item'] || [];
    }
  }

  buildAllMaintenancePackages(maintenancePackages: MaintenancePackage[]) {

    maintenancePackages.forEach((maintenancePackage, index) => {
      maintenancePackage['maintenance_line_item'].forEach(lineItem => {
        lineItem['isExpanded'] = false;
      });
      this.allMaintenancePckgs.push({
        'package_id': maintenancePackage['id'],
        'mileage': ((maintenancePackage['interval'].value / 1000) + 'K'),
        'recommended': maintenancePackage['recommended'],
        'isSelected': maintenancePackage['recommended'],
        'maintenance_line_item': maintenancePackage['maintenance_line_item'],
        'description': maintenancePackage['description']
      });
      if (maintenancePackage['recommended']) {
        this.isMaintenanceRecommendedPackage = true;
        this.recommendedPackageIndex = index;
        this.maintenanceLineItems = maintenancePackage['maintenance_line_item'];
        this.selectedMaintenancePackage = {
          'package_id': maintenancePackage['id'],
          'mileage': ((maintenancePackage['interval'].value / 1000) + 'K'),
          'recommended': maintenancePackage['recommended'],
          'isSelected': maintenancePackage['recommended'],
          'maintenance_line_item': maintenancePackage['maintenance_line_item'],
          'description': maintenancePackage['description']
        };
      }
    });
    this.vehicleConfigService.setManipulatedMaintenanceIntervals(this.allMaintenancePckgs);
  }

  scrollToRecomendedInterval(recommendedPackageIndex) {
    let elmnt = document.getElementById('upgServiceTimeline');
    let myElement = document.getElementById(recommendedPackageIndex + '-MI');
    let topPos = myElement.offsetLeft;

    let view_port_width = this.getViewportSize().w;

    if (!_.isNil(view_port_width) && ((view_port_width >= 1024 && recommendedPackageIndex >= 7) ||
      (view_port_width >= 530 && recommendedPackageIndex >= 2) ||
      (view_port_width < 530 && recommendedPackageIndex >= 2))) {
      elmnt.scrollLeft = topPos - 50;
    }
    let listElement = document.getElementById('upgServiceTimelineList');
    listElement.style.width = (125 * this.allMaintenancePckgs.length) + 'px';
  }

  onClickLineItem(lineItem: MaintenanceLineItem) {
    lineItem.isExpanded = !lineItem.isExpanded;
    this.calculateScreenHeight();
  }

  onClickTimeBasedServiceLineItem(service: TimeBasedService) {
    service.isExpanded = !service.isExpanded;
    this.calculateScreenHeight();
  }

  loadMaintenancePackageById(packageId: string) {

    this.cloudService.saveAuditDetails(this.eventReport, 'Maintenance_Page_Click');

    this.clearSelection();

    this.selectedMaintenancePackage = this.allMaintenancePckgs.find(maintenancePackage => maintenancePackage.package_id === packageId);

    this.selectedMaintenancePackage.isSelected = true;

    this.maintenanceLineItems = this.selectedMaintenancePackage.maintenance_line_item;

    this.isMaintenanceRecommendedPackage = this.getPackageStatus(packageId);

    this.timeBasedServices.forEach((service => {
      if (service.notes.length > 1) {
        service.isExpanded = false;
      }
    }));

    this.calculateScreenHeight();
  }

  splitNotesIntoColumns(notes: string[]): { column1: string[], column2: string[] } {
    const halfIndex = Math.ceil(notes.length / 2);
    return {
      column1: notes.slice(0, halfIndex),
      column2: notes.slice(halfIndex),
    };
  }

  removeHyphen(note: string): string {
    return note.startsWith('-') ? note.slice(1).trim() : note;
  }

  clearSelection() {
    this.allMaintenancePckgs.forEach((maintenancePackage) => {
      maintenancePackage['isSelected'] = false;
      maintenancePackage['maintenance_line_item'].forEach(lineItem => {
        lineItem['isExpanded'] = false;
      });
    });
  }

  calculateScreenHeight() {
    setTimeout(() => {
      this.isFixedFooter = document.documentElement.scrollHeight > this.screenHeight;
    });
  }

  getPackageStatus(packageId?: string) {
    for (let interval of this.allMaintenancePckgs) {
      if (interval.package_id === packageId && interval.recommended) return true;
    }
    return false;
  }

  isSelectedInterval(packageId: string): boolean {
    if (this.selectedMaintenancePackage) return packageId === this.selectedMaintenancePackage.package_id;
  }

  goBack(event?: any) {
    this.cloudService.setIsVehicleInContext(true);
    this.vehicleConfigService.setVehicleInContext(true);
    console.log('=>=>=> VEHICLE CONTEXT GOBACK - ', this.vehicleConfigService.getVehicleContext());
    this.router.navigate(['../']);
  }


  print(event) {
    event.preventDefault();
    setTimeout(() => window.print(), 100);
  }

  isIphone() {
    return /iPhone/.test(navigator.userAgent) && !window['MSStream'];
  }

  isIpad() {
    if (/\b(iPad)\b/.test(navigator.userAgent) && /WebKit/.test(navigator.userAgent) && !window['MSStream']) {
      return true;
    }
    return false;
  }

  getPrintTitle() {
    return (this.brand == 'A') ? 'Audi Maintenance Schedule' : 'VW Maintenance Schedule';
  }

  trackLineItems(index, item) {
    return index;
  }

  toTop() {
    setTimeout(() => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }

  getViewportSize(w?) {
    w = w || window;

    // Works for all browsers except IE8 and before
    if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

    // For IE (or any browser) in Standards mode
    var d = w.document;
    if (document.compatMode == 'CSS1Compat')
      return {
        w: d.documentElement.clientWidth,
        h: d.documentElement.clientHeight
      };

    // For browsers in Quirks mode
    return { w: d.body.clientWidth, h: d.body.clientHeight };
  }

  ngAfterViewInit() {
    this.calculateScreenHeight();
    this.toTop();
    this.scrollToRecomendedInterval(this.recommendedPackageIndex);
    this.cloudService.setIsMenuClickedInFRM(false);
  }

  onMenuClick() {
    this.cloudService.setIsMenuClickedInFRM(true);
    this.vehicleConfigService.setManipulatedMaintenanceIntervals(this.allMaintenancePckgs);
    this.guard.allow = true;
    this.router.navigateByUrl('menu', { skipLocationChange: true });
  }

  showPrintOption() {
    this.cloudService.saveAuditDetails(this.eventReport, 'Click_Print');
    this.dialog.closeAll();
    this.dialog.open(UpgDialogComponent, {
      data: {}
    });
  }

  onContactUsFooterSelect(type: string){
    if (type === 'PRIVACY_POLICY') {
      this.cloudService.saveAuditDetails(this.eventReport, 'Click_Privacy_Policy_in_Footer');
    } else {
      this.cloudService.saveAuditDetails(this.eventReport, 'Click_Contact_Us_in_Footer');
    }
  }

  onLanguageToggleSelect(type: string){
    this.event.entry_point_url = location.host;
    this.event.source = this.browser;
    this.event.source_type = this.device;
    this.event.user_brand = this.brand;
    this.event.user_brand = this.brand;
    this.event.user_importer_code = this.importerCode;
    this.event.vehicle_brand = this.brand;
    this.event.vehicle_importer_code = this.importerCode;
    if (this.importerCode == '444') {
      this.event.language_code = this.userSelectedLanguage + "-US";
    } else {
      this.event.language_code = type + "-CA";
    }
    //if(!_.isNil(this.event.vin) || !_.isNil(this.event.vehicle_trim)){
    if(!_.isNil(this.event.vin)){
      //VINorVehicleBuildIsSelected 
    }else{
      this.event.model_code = "ABCDEF";
      this.event.model_year = 9999;
      this.event.model_name = 'NA';
      this.event.vehicle_trim = 'NA';
      this.event.vin='NA';
    }
    this.cloudService.saveAuditDetails(this.event, 'Click_Language_Toggle');
  }

  onLanguageSelectionChanged(newLanguage: string) {
    this.onLanguageToggleSelect(newLanguage);
    if (this.userSelectedLanguage === newLanguage) {
      return;
    }
    this.userSelectedLanguage = newLanguage;
    this.translate.use(newLanguage);
    this.environmentService.setLanguage(newLanguage);
    this.environmentService.setLanguageCode((newLanguage === CONST_FR_LANG) ? CONST_FR_CA_LANG_CODE : CONST_EN_US_LANG_CODE);
    this.environmentService.setLocale((newLanguage === CONST_FR_LANG) ? CONST_FR_CA_LOCALE : CONST_EN_US_LOCALE);

    this.fetchModelYears();
    if (this.vehicleContextForPrint.vin) {
      this.searchForPackages();

    } else {
      this.vehicleConfigService.clearVariables();
      const brand = this.brand == 'A' ? 'audi' : 'vw';
      this.router.navigate([`/search/${newLanguage}/${brand}/${this.importerCode}`]);
    }

  }


  fetchModelYears(): void {
    this.cloudService.fetchModelYears().pipe(takeUntil(this.maintenancePageDestroyed$)).subscribe((modelYears: ModelYear[]) => {
      this.modelYears = modelYears;
      this.cloudService.setVehicleContextModelYears(modelYears);
    }, error => {
      this.getModelYearErrorOccurred = true;
      this.cloudService.showPopup('An error occurred while fetching the model years');
    });
  }

  ngOnDestroy() {
    //Garbage collect all subscriptions
    document.body.style.backgroundColor = '#FFFFFF';
    this.maintenancePageDestroyed$.next(true);
    this.maintenancePageDestroyed$.complete();
    this.maintenancePageDestroyed$.unsubscribe();
  }
}
