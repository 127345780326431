import { VehicleConfigLookupService } from './../services/vehicle-config-lookup.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upg-dialog',
  templateUrl: './upg-dialog.component.html',
  styleUrls: ['./upg-dialog.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class UpgDialogComponent implements OnInit {

  maintenanceCardUrl: string = '';

  constructor(public dialogRef: MatDialogRef<UpgDialogComponent>, private vehicleConfigService: VehicleConfigLookupService) { }

  ngOnInit() {
    console.log("DIALOG");
    console.log(this.dialogRef);
    this.maintenanceCardUrl = this.vehicleConfigService.getMaintenanceCardUrl();
  }

  printScreen() {
    this.dialogRef.close();
    setTimeout(() => window.print(), 100);
  }

  downloadMaintenanceCard() {
    this.closeDialog();
    window.open(this.maintenanceCardUrl, '_blank');
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
