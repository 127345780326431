import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable()
export class VinValidators{
    static hasCorrectLength(control: AbstractControl):ValidationErrors | null {
        let length = 17;
        // let isValid = !length;
        let value = control.value.trim();

        if (value.length !== length) {
            return { 'hasCorrectLength': true }
        } else {
            return null;
        }
    }

    static areLastSixDigitsNumeric(control: AbstractControl):ValidationErrors | null {
        let value = control.value.trim() || "";
        let subValue = value.substr(-6);

        if (isNaN(+subValue)) return { 'areLastSixDigitsNumeric': true }
        else return null;
    }

    static isVINPatternValid(control: AbstractControl):ValidationErrors | null {
        let value = control.value.trim() || "";
        let testRegex : RegExp =/^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dXx][A-HJ-NPR-Za-hj-npr-z\d]{3}\d{5}$/;

        if(!testRegex.test(value)){
            return { 'isVINPatternValid': true }
        }else{
            return null;
        }
    }
}
