// Application specific error
export class AppError {
    // Pass error param to use for logging
    constructor(public originalError?: Response) {}
}

export class GetModelYearsError extends AppError {}

export class GetCarLinesError extends AppError {}

export class GetModelTrimsError extends AppError {}

export class GetMaintenancePackagesError extends AppError {}

export class FetchOwnersLiteratureDocumentsError extends AppError {}

export class GetVehicleConfigError extends AppError {}

export class GetWhiteListDataError extends AppError {}

export class FetchOwnersManualError extends AppError {}
