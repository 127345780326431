import { TimeBasedService } from './../models/time-based-service.model';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import _ from 'lodash';

import { GetModelYearsError, GetCarLinesError, GetModelTrimsError, GetWhiteListDataError } from './../errors/app-error';
import { GetMaintenancePackagesError, FetchOwnersLiteratureDocumentsError, GetVehicleConfigError } from './../errors/app-error';
import { ModelYear } from './../models/model-year.model';
import { CarLine } from './../models/car-line.model';
import { ModelTrim } from './../models/model-trim.model';
import { MaintenancePackage, MaintenanceIntervals } from './../models/maintenance-package.model';

import { ODOMETER_UNIT, URL_ORDER_GUIDE_SERVICE, URL_MAINTENANCE_PCKG, URL_VEHICLE_INFORMATION, URL_POST_EVENT } from './../app.constants';
import { URL_FIND_OWNERS_LITERATURE_DOCS } from './../app.constants';
import { URL_GET_WHITELIST_DATA } from './../app.constants';

import { URL_MODEL_YEARS, URL_MODEL_TRIMS, URL_CAR_LINES, URL_PACKAGES, URL_MODEL_TRIM } from './../app.constants';
import { VehicleContextObj } from '../models/vehicle-context.model';
import { EnvironmentService } from './environment.service';
import { VehicleConfig } from '../models/vehicle-config.model';
import { EventReport } from '../models/event-report-model';


@Injectable({
  providedIn: 'root'
})
export class CloudService {

  @Output()  showAlert = new EventEmitter<string>();

  allMaintenancePackages: MaintenancePackage[] = [];
 
  timeBasedServices: TimeBasedService[] = [];

  maintenanceResponse = {};
  ownersLiteratureResponse = {};

  errorOccurred = false;

  isVehicleInContext = false;
  isMenuClickedInFRM: boolean = false;

  vehicleContextModelYears: ModelYear[] = [];
  vehicleContextCarLines: CarLine[] = [];
  vehicleContextModelTrims: ModelTrim[] = [];

  emissionPartsListFilter = {};

  maintenanceIntervals: MaintenanceIntervals[] = [];

  packageValues = {};

  postEventRequestURL = this.environmentService.getEventServiceOrigin()+ URL_POST_EVENT;
  constructor(private http: HttpClient,private environmentService: EnvironmentService) { }

  getVehicleContextModelYears(): ModelYear[] {
    return this.vehicleContextModelYears;
  }

  setVehicleContextModelYears(modelYears: ModelYear[]) {
    this.vehicleContextModelYears = modelYears;
  }

  getVehicleContextCarLines(): CarLine[] {
    return this.vehicleContextCarLines;
  }

  setVehicleContextCarLines(carLines: CarLine[]) {
    this.vehicleContextCarLines = carLines;
  }

  getVehicleContextModelTrims(): ModelTrim[] {
    return this.vehicleContextModelTrims;
  }

  setVehicleContextModelTrims(modelTrims: ModelTrim[]) {
    this.vehicleContextModelTrims = modelTrims;
  }

  getIsVehicleInContext(): boolean {
    return this.isVehicleInContext;
  }

  setIsVehicleInContext(isVehicleInContext: boolean) {
    this.isVehicleInContext = isVehicleInContext;
  }

  setIsMenuClickedInFRM(isClicked: boolean) {
    this.isMenuClickedInFRM = isClicked;
  }

  getIsMenuClickedInFRM() {
    return this.isMenuClickedInFRM;
  }

  getEmissionPartsListFilter(): {} {
    return this.emissionPartsListFilter;
  }

  getPackageValues() {
    return this.packageValues;
  }

  setPackageValues(vehicleContext: VehicleContextObj) {
    this.packageValues = vehicleContext
  }

  loadEmissionPartsListFilter() {
    const xmlHttpRequest = new XMLHttpRequest();
    xmlHttpRequest.addEventListener('load', (data) => {
      this.emissionPartsListFilter = JSON.parse(data['currentTarget']['responseText']) || '{}';
    });
    xmlHttpRequest.open('GET', 'assets/json/emissionPartsListFilter.json', true);
    xmlHttpRequest.send();
  }

  getModelTrim(modelYear: number, salesModelCode: string) {

    let searchParams = new HttpParams();
    searchParams = searchParams.append('importer-code', this.environmentService.getImporterCode());
    searchParams = searchParams.append('language-code', this.environmentService.getLanguageCode());
    searchParams = searchParams.append('brand', this.environmentService.getBrand());
    searchParams = searchParams.append('model-year', modelYear.toString());
    searchParams = searchParams.append('sales-model-code', salesModelCode);

    const getModelTrimRequest = this.environmentService.getOrderGuideOrigin() + URL_ORDER_GUIDE_SERVICE + URL_MODEL_TRIM;

    return this.http.get<ModelTrim>(getModelTrimRequest, { params: searchParams, responseType: 'json' }).pipe(map(responseData => {
      const modelTrim: ModelTrim = responseData['model_trim'];
      return modelTrim;
    }), catchError((error: Response) => {
      return throwError(error);
    }));
  }

  fetchModelYears() {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('importer-code', this.environmentService.getImporterCode());
    searchParams = searchParams.append('language-code', this.environmentService.getLanguageCode());
    searchParams = searchParams.append('brand', this.environmentService.getBrand());

    const fetchModelYearRequest = this.environmentService.getOrderGuideOrigin() + URL_ORDER_GUIDE_SERVICE + URL_MODEL_YEARS;

    return this.http.get<ModelYear[]>(fetchModelYearRequest, {params: searchParams, responseType: 'json'}).pipe(map(responseData => {
      const modelYears: ModelYear[] = responseData['model_years'];
      return modelYears;
    }), catchError((error: Response) => {
      return throwError(new GetModelYearsError(error));
    }));
  }

  fetchCarLines(year: number) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('importer-code', this.environmentService.getImporterCode());
    searchParams = searchParams.append('language-code', this.environmentService.getLanguageCode());
    searchParams = searchParams.append('brand', this.environmentService.getBrand());
    searchParams = searchParams.append('model-year', year.toString());

    // return of(carLines)
    const fetchCarLinesRequest =  this.environmentService.getOrderGuideOrigin() + URL_ORDER_GUIDE_SERVICE + URL_CAR_LINES;

    return this.http
    .get<CarLine[]>(fetchCarLinesRequest, {params: searchParams, responseType: 'json'}).pipe(map(responseData => {
      const carLines: CarLine[] = responseData['car_lines'];
      return carLines;
    }), catchError((error: Response) => {
      return throwError(new GetCarLinesError(error));
    }));
  }

  fetchModelTrims(modelYear: number, carLine: string) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('importer-code', this.environmentService.getImporterCode());
    searchParams = searchParams.append('language-code', this.environmentService.getLanguageCode());
    searchParams = searchParams.append('brand', this.environmentService.getBrand());
    searchParams = searchParams.append('model-year', modelYear.toString());
    searchParams = searchParams.append('car-line', carLine.toString());

    const fetchModelTrimsRequest =  this.environmentService.getOrderGuideOrigin() + URL_ORDER_GUIDE_SERVICE + URL_MODEL_TRIMS;

    return this.http.get<CarLine[]>(fetchModelTrimsRequest, {params: searchParams, responseType: 'json'}).pipe(map(responseData => {
      const modelTrims: ModelTrim[] = responseData['model_trims'];
      return modelTrims;
    }), catchError((error: Response) => {
      return throwError(new GetModelTrimsError(error));
    }));
  }

  fetchMaintenancePacakges(vehicleContext: VehicleContextObj): Observable<any> {

    let requestParams = new HttpParams();

    requestParams = requestParams.append('importer-code', this.environmentService.getImporterCode());
    requestParams = requestParams.append('brand', this.environmentService.getBrand());
    requestParams = requestParams.append('language-code', this.environmentService.getLanguageCode());
    requestParams = requestParams.append('odometer-value', (vehicleContext.odometer).toString());
    requestParams = requestParams.append('odometer-unit', ODOMETER_UNIT);
    if (!_.isNil(vehicleContext.vin)) {
      requestParams = requestParams.append('vin', vehicleContext.vin.trim());
    } else {
      requestParams = requestParams.append('model-year', (vehicleContext.modelYear).toString());
      requestParams = requestParams.append('sales-model-code', vehicleContext.modelTrim);
    }

    const fetchMaintenancePackageRequest = this.environmentService.getMpmsOrigin() + URL_MAINTENANCE_PCKG + URL_PACKAGES;

    return this.http.get<MaintenancePackage[]>(fetchMaintenancePackageRequest, { params: requestParams, responseType: 'json' }).pipe
    (map(responseData => {
      const packages: MaintenancePackage[] = responseData['packages'];
      return responseData;
    }), catchError((error: Response) => {
      return throwError(new GetMaintenancePackagesError(error));
    }));
  }

  fetchOwnersLiteratureDocuments(vehicleContext: VehicleContextObj) {
    let requestParams = new HttpParams();
    requestParams = requestParams.append('brand', this.environmentService.getBrand());
    requestParams = requestParams.append('importer-code', this.environmentService.getImporterCode());
    requestParams = requestParams.append('language-code', this.environmentService.getLanguageCode());

    if (!_.isNil(vehicleContext['vin'])) {
      requestParams = requestParams.append('vin', vehicleContext['vin'].trim());
    } else {
      requestParams = requestParams.append('model-year', (vehicleContext['modelYear']).toString());
      requestParams = requestParams.append('model-code', vehicleContext['modelTrim'].substring(0, 3));
    }

    const fetchOwnersLiteratureDocumentsRequest = this.environmentService.getOwnersLiteratureOrigin() + URL_FIND_OWNERS_LITERATURE_DOCS;

    return this.http.get(fetchOwnersLiteratureDocumentsRequest, {params: requestParams, responseType: 'json'}).pipe(map(responseData => {
      return responseData;
    }), catchError((error: Response) => {
      return throwError(new FetchOwnersLiteratureDocumentsError(error));
    }));
  }

  fetchVehicleConfigurationInformation(vin: string) {
    let requestParams = new HttpParams();
    requestParams = requestParams.append('vin', vin.trim().toString());

    const fetchVehicleConfigRequest = this.environmentService.getMpmsOrigin()+ URL_MAINTENANCE_PCKG + URL_VEHICLE_INFORMATION;

    return this.http.get<VehicleConfig>(fetchVehicleConfigRequest, { params: requestParams, responseType: 'json' }).pipe
    (map(responseData => {
      const vehicleConfig: VehicleConfig = responseData;
      return vehicleConfig;
    }), catchError((error: Response) => {
      return throwError(new GetVehicleConfigError(error));
    }));
  }

  fetchWhiteListData() {
    const endPoint = this.environmentService.getOwnersLiteratureOrigin() + URL_GET_WHITELIST_DATA;
    return this.http.get(endPoint).pipe(map(whitelistResponseData => {
      return whitelistResponseData;
    }), catchError((error: Response) => {
      return throwError(new GetWhiteListDataError(error));
    }));
  }

  saveAuditDetails(eventReport: EventReport, eventType: String) {
    if(!_.isNil(eventReport)){
      var postdata = {
          "entry_point_url":eventReport.entry_point_url,  
          "vehicle_trim":eventReport.vehicle_trim,
          "type": eventType,
          "source": eventReport.source,
          "source_type": eventReport.source_type,
          "user_brand": eventReport.user_brand,
          "user_importer_code": eventReport.user_importer_code,
          "vehicle_brand": eventReport.vehicle_brand,
          "vehicle_importer_code": eventReport.vehicle_importer_code,
          "language_code": eventReport.language_code,
          "vin": (eventReport.vin && eventReport.vin.trim()) || null,
          "mileage": eventReport.mileage,
          "model_name": eventReport.model_name,
          "model_year": eventReport.model_year,
          "model_code": eventReport.model_code
      };
      this.http.post(this.postEventRequestURL, postdata).subscribe(res=>{"Sucessfully Saved"}, error=>{console.log('Event service is failed')});
    }
  }

  showPopup(message: string) {
    this.showAlert.emit(message);
  }
}
