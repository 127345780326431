import { CONST_IMPORTER_CODE_CA, CONST_BRAND_AUDI, URL_MPMS_ORIGIN_PROD, URL_MPMS_ORIGIN_DEV, URL_MPMS_ORIGIN_QA, URL_ORDERGUIDE_ORIGIN_PROD, URL_ORDERGUIDE_ORIGIN_DEV, URL_ORDERGUIDE_ORIGIN_QA, URL_OWNERS_LITERATURE_ORIGIN_PROD, URL_OWNERS_LITERATURE_ORIGIN_DEV, URL_OWNERS_LITERATURE_ORIGIN_QA, CONST_BRAND_VW, CONST_IMPORTER_CODE_US, CONST_EN_LANG, CONST_APP_ORIGIN_US_VW, CONST_APP_ORIGIN_CA_VW, CONST_APP_ORIGIN_US_AUDI, CONST_APP_ORIGIN_CA_AUDI, CONST_EN_US_LOCALE, CONST_FR_LANG, CONST_FR_CA_LOCALE, CONST_FR_CA_LANG_CODE, CONST_EN_US_LANG_CODE, CONST_APP_TITLE_AUDI, CONST_APP_TITLE_VW, CONST_APP_ORIGIN_CA_AUDI_QA, URL_EVENT_SERVICE_ORIGIN_DEV, URL_EVENT_SERVICE_ORIGIN_QA, URL_EVENT_SERVICE_ORIGIN_PROD,URL_OWNERS_MANUAL_VW_GETTOKEN_ORIGIN_PROD,URL_OWNERS_MANUAL_VW_GETTOKEN_ORIGIN_QA, URL_OWNERS_MANUAL_VW_SITE_ORIGIN_PROD, URL_OWNERS_MANUAL_VW_SITE_ORIGIN_QA } from './../app.constants';
import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private brand: string = CONST_BRAND_VW;
  private importerCode: string = CONST_IMPORTER_CODE_US;
  private language: string = CONST_EN_LANG;
  private languageCode: string = CONST_EN_LANG;
  private locale: string = CONST_EN_US_LOCALE;
  private urlOrigin: string;
  private routeParams: {};
  private privacyUrl: string;
  private contactUsUrl: string;

  link: HTMLElement;
  currentStyles: HTMLElement;

  constructor() {
    this.urlOrigin = window.location.origin;
  }

  getBrand(): string {
    return this.brand;
  }

  setBrand(brand: string) {
    this.brand = brand;
  }

  getImporterCode(): string {
    return this.importerCode;
  }

  setImporterCode(importerCode: string) {
    this.importerCode = importerCode;
  }

  getLanguage(): string {
    return this.language;
  }

  setLanguage(language: string) {
    this.language = language;
  }

  getLanguageCode(): string {
    return this.languageCode;
  }

  setLanguageCode(languageCode: string) {
    this.languageCode = languageCode;
  }

  getLocale(): string {
    return this.locale;
  }

  setLocale(locale: string) {
    this.locale = locale;
  }

  isCanadianMarket(): boolean {
    return (this.importerCode === CONST_IMPORTER_CODE_CA);
  }

  isAudiBrand(): boolean {
    return (this.brand === CONST_BRAND_AUDI);
  }

  getRouteParams(): {} {
    return this.routeParams;
  }

  setRouteParams(routeParams: {}) {
    this.routeParams = routeParams;
  }

  getPrivacyUrl(): string {
    return this.privacyUrl;
  }

  setPrivacyUrl(url: string) {
    this.privacyUrl = url;
  }

  getContactUsUrl(): string {
    return this.contactUsUrl;
  }

  setContactUsUrl(url: string) {
    this.contactUsUrl = url;
  }

  isDevEnvironment(): boolean {
    return (this.urlOrigin.indexOf('localhost') > 0  || this.urlOrigin.indexOf('dev') > 0);
  }

  isQAEnvironment(): boolean {
    let isQA: boolean = false;
    if (this.isQaAudiMaintenanceOrigin()) {
      isQA = true;
    } else if (this.urlOrigin.indexOf('qa') > 0) {
      isQA = true;
    }
    return isQA;
  }

  isQaAudiMaintenanceOrigin() {
    return (this.urlOrigin.toLowerCase().indexOf(CONST_APP_ORIGIN_CA_AUDI_QA) >= 0 || this.urlOrigin.toLowerCase().indexOf('qa-maintenance.audiusa.com') >= 0);
  }

  getMpmsOrigin(): string {
    let mpmsOrigin = URL_MPMS_ORIGIN_PROD;
    if (this.isDevEnvironment()) {
      mpmsOrigin = URL_MPMS_ORIGIN_DEV;
    } else if (this.isQAEnvironment()) {
      mpmsOrigin = URL_MPMS_ORIGIN_QA;
    }
    return mpmsOrigin;
  }

  getOrderGuideOrigin(): string {
    let orderGuideOrigin = URL_ORDERGUIDE_ORIGIN_PROD;
    if (this.isDevEnvironment()) {
      orderGuideOrigin = URL_ORDERGUIDE_ORIGIN_DEV;
    } else if (this.isQAEnvironment()) {
      orderGuideOrigin = URL_ORDERGUIDE_ORIGIN_QA;
    }
    return orderGuideOrigin;
  }

  getOwnersLiteratureOrigin(): string {
    let literatureOrigin = URL_OWNERS_LITERATURE_ORIGIN_PROD;
    if (this.isDevEnvironment()) {
      literatureOrigin = URL_OWNERS_LITERATURE_ORIGIN_DEV;
    } else if (this.isQAEnvironment()) {
      literatureOrigin = URL_OWNERS_LITERATURE_ORIGIN_QA;
    }
    return literatureOrigin;
  }
  //AMS : getOwnersManualOriginTokenForVWUS
  getOwnersManualOriginTokenForVWUS(): string {
    let ownersManualTokenOrigin = URL_OWNERS_MANUAL_VW_GETTOKEN_ORIGIN_PROD
    if (this.isDevEnvironment() || this.isQAEnvironment())
      ownersManualTokenOrigin = URL_OWNERS_MANUAL_VW_GETTOKEN_ORIGIN_QA
    return ownersManualTokenOrigin;
  }
  //AMS : getOwnersManualOriginTokenForVWUS
  getOwnersManualOriginSiteForVWUS(): string {
    let ownersManualSiteOrigin = URL_OWNERS_MANUAL_VW_SITE_ORIGIN_PROD;
    if (this.isDevEnvironment() || this.isQAEnvironment())
      ownersManualSiteOrigin = URL_OWNERS_MANUAL_VW_SITE_ORIGIN_QA
    return ownersManualSiteOrigin;
  }

  getEventServiceOrigin(): string {
    let literatureOrigin = URL_EVENT_SERVICE_ORIGIN_PROD;
    if (this.isDevEnvironment()) {
      literatureOrigin = URL_EVENT_SERVICE_ORIGIN_DEV;
    } else if (this.isQAEnvironment()) {
      literatureOrigin = URL_EVENT_SERVICE_ORIGIN_QA;
    }
    return literatureOrigin;
  }

  updateEnvironmentVariablesInDevOrQA(brand, importerCode, language) {
    this.brand = brand;
    this.importerCode = importerCode;
    this.language = language;
    this.languageCode = ((language === CONST_FR_LANG) ? CONST_FR_CA_LANG_CODE : CONST_EN_US_LANG_CODE);
    this.locale = ((language === CONST_FR_LANG) ? CONST_FR_CA_LOCALE : CONST_EN_US_LOCALE);

    this.applyAppTheme();
  }

  findEnvironmentVariablesFromOrigin() {
    this.brand = CONST_BRAND_VW;
    this.importerCode = CONST_IMPORTER_CODE_US;
    this.language = CONST_EN_LANG;
    this.languageCode = CONST_EN_US_LANG_CODE;
    this.locale = CONST_EN_US_LOCALE;
    if (this.urlOrigin.toLowerCase().indexOf(CONST_APP_ORIGIN_US_VW) >= 0) {
      this.brand = CONST_BRAND_VW;
      this.importerCode = CONST_IMPORTER_CODE_US;
    } else if (this.urlOrigin.toLowerCase().indexOf(CONST_APP_ORIGIN_CA_VW) >= 0) {
      this.brand = CONST_BRAND_VW;
      this.importerCode = CONST_IMPORTER_CODE_CA;
    } else if (this.urlOrigin.toLowerCase().indexOf(CONST_APP_ORIGIN_CA_AUDI_QA) >= 0 || this.urlOrigin.toLowerCase().indexOf('qa-maintenance.audiusa.com') >= 0) {
      this.brand = CONST_BRAND_AUDI;
      this.importerCode = CONST_IMPORTER_CODE_CA;
    } else if (this.urlOrigin.toLowerCase().indexOf(CONST_APP_ORIGIN_US_AUDI) >= 0) {
      this.brand = CONST_BRAND_AUDI;
      this.importerCode = CONST_IMPORTER_CODE_US;
    } else if (this.urlOrigin.toLowerCase().indexOf(CONST_APP_ORIGIN_CA_AUDI) >= 0) {
      this.brand = CONST_BRAND_AUDI;
      this.importerCode = CONST_IMPORTER_CODE_CA;
    }
  }

  applyAppTheme(language?: string) {
    if (!_.isNil(language)) {
      this.language = language;
      this.languageCode = ((language === CONST_FR_LANG) ? CONST_FR_CA_LANG_CODE : CONST_EN_US_LANG_CODE);
      this.locale = ((language === CONST_FR_LANG) ? CONST_FR_CA_LOCALE : CONST_EN_US_LOCALE);
    }

    this.updateAssets(this.brand, this.importerCode);
    this.updateTheme();
    this.updateStorage();
    this.updateBackgroundImage();
  }

  updateAssets(brand: string, importerCode: string) {
    this.link = document.getElementById('favicon');
    const windowTitle = document.getElementById('upg-web-title');
    if (!this.isAudiBrand()) {
      this.link.setAttribute('href', 'vw.ico');
      windowTitle.innerText = CONST_APP_TITLE_VW;
    } else {
      this.link.setAttribute('href', 'audi.ico');
      windowTitle.innerText = CONST_APP_TITLE_AUDI;
    }
  }

  updateTheme() {
    if (!this.isAudiBrand()) {
      document.body.classList.remove('audi');
      document.body.classList.add('volkswagen');
    } else {
      document.body.classList.remove('volkswagen');
      document.body.classList.add('audi');
    }
  }

  updateBackgroundImage() {
    if (!this.isAudiBrand()) {
      document.body.classList.remove('cf-audi-body');
      document.body.classList.add('cf-vw-body');
    } else {
      document.body.classList.remove('cf-vw-body');
      document.body.classList.add('cf-audi-body');
    }
  }

  removeBackgroundImage() {
    if (!this.isAudiBrand()) {
      document.body.classList.remove('cf-vw-body');
    } else {
      document.body.classList.remove('cf-audi-body');
    }
  }

  updateStorage() {
    localStorage.removeItem('CF_UPG_BRAND');
    localStorage.setItem('CF_UPG_BRAND', this.brand);
  }
}
