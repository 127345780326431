import { EnvironmentService } from './services/environment.service';

import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params, RoutesRecognized, ParamMap, RouterOutlet } from '@angular/router';

import { CloudService } from './services/cloud.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { VehicleConfigLookupService } from './services/vehicle-config-lookup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  constructor( private router: Router, private route: ActivatedRoute,
               private cloudService: CloudService,
               private environmentService: EnvironmentService,
               private vehicleConfigService: VehicleConfigLookupService,
               public changeDetectorRef: ChangeDetectorRef,
               public translate: TranslateService) {
    this.initTranslate();
    this.vehicleConfigService.setVehicleInContext(false);
    this.cloudService.setIsVehicleInContext(false);
    this.cloudService.loadEmissionPartsListFilter();
  }

  initTranslate() {
    let language = '';
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    language = 'en';
    this.environmentService.setLanguage(language);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // Garbage collect all subscriptions
    // this.cloudService.clearVehicleContext(); TO DO CLEARING VEHICLE CONTEXT
    this.cloudService.setIsVehicleInContext(false);
    this.cloudService.setIsMenuClickedInFRM(false);
    this.vehicleConfigService.setVehicleInContext(false);
    this.vehicleConfigService.clearVariables();
    // this.cloudService.setIsElectricVehicle(false); TO DO CLEARING VEHICLE CONFIG
    // this.cloudService.setMaintenanceIntervals([]);
    // this.cloudService.setRouterParams({}); TO DO CLEARING ROUTE PARAMS
  }
}
