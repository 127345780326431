import { EnvironmentService } from './../services/environment.service';
import { Component, OnInit } from '@angular/core';

import { CloudService } from './../services/cloud.service';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  isModalVisible = false;
  message = "";
  themeBrand = 'V';

  constructor( private cloudService: CloudService, private environmentService: EnvironmentService) { }

  ngOnInit() {
    this.cloudService.showAlert.subscribe(
      message => {
        this.message = message;
        this.isModalVisible = true;
        this.themeBrand = this.environmentService.getBrand();
      }
    );
  }

  closeModal(){
    this.isModalVisible = false;
  }

}
