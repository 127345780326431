import { SearchComponent } from './../search/search.component';
import { MaintenanceComponent } from './../packages/maintenance.component';
import { Injectable, NgModule } from '@angular/core';
// import { Location } from '@angular/common';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';


@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '**',
        canActivate: [CanDeactivateGuard]
      }
    ])
  ],
  providers: [CanDeactivateGuard]
})

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanActivate {

  // must be set to true for navigation to succeed
  allow = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (this.allow) {
      this.allow = false;
      return true;
    } else {
      return false;
    }
  }
}
