import { Directive, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appVinInput]'
})
export class VinInputDirective {

  testRegex : RegExp;

  constructor(private renderer: Renderer2,
    private el: ElementRef){
     this.testRegex = /^[a-hj-npr-z0-9]*$/i;
  }


  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {

      if (event && event.key) {
          var char = event.key.trim().toLowerCase();
          if (!this.testRegex.test(char)){
              event.preventDefault();
          }
      }
      var _this = this;
      setTimeout(function(){
        _this.el.nativeElement.value = _this.el.nativeElement.value.toUpperCase().trim();
      });

  }

  @HostListener('change', ['$event'])
  onChange(event: any) {
      this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase().trim();
  }

//   @HostListener('paste', ['$event'])
//   onPaste(event: ClipboardEvent) {
//     if (event && event.clipboardData) {
//         var pasteText: string = event.clipboardData.getData('text/plain');
//         if (pasteText) {
//             var _this = this;
//             setTimeout(function(){
//                 _this.el.nativeElement.value = _this.el.nativeElement.value.toUpperCase().trim();
//             });
//         }
//     }
//   }
}
